.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust color and opacity */
    /* display: ${(props) => (!props.open && 'none')}; */
    z-index: 500; 
    .hidden {
        display: none;
    }
}

