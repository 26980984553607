#customer-reviews{
    font-size: 30px !important;
    font-weight: 600 !important;
    margin-top: 24px;
}
/* #customer-reviews-main hr {
    width: 150px;
    height: 4px;
    border-radius: 10px;
    background: #252525;
    margin: 0 auto;
} */
@media(max-width:768px){
    #customer-reviews{
        font-size: 30px !important;
    }
}

.customer-reviews hr {
    width: 100px;
    height: 4px;
    border-radius: 10px;
    background: #252525;
    margin: 0 auto;
  }